import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from "./context/AuthProvider";
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store';

ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <AuthProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </AuthProvider>
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

