import axios from "axios";

export default axios.create({
    baseURL: 'https://ppp.bloc.asia/api.php'
});

export const axiosPrivate = axios.create({
    baseURL: 'https://ppp.bloc.asia/api.php',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

