import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Container, Paper, Stack, TextField, Select, MenuItem, Button, CircularProgress, Typography, OutlinedInput, IconButton, InputAdornment, AppBar, Toolbar, CssBaseline, Divider } from '@mui/material';
import { EyeOutlined, EyeInvisibleOutlined, LineOutlined } from '@ant-design/icons';
import { requestNetwork } from '../../services/http-service';
import { toast } from 'react-toastify';

const RegisterScreen = () => {
    const navigate = useNavigate();
    const [isSubmiting, setSubmiting] = useState(false);
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const handleChange = (e) => {
        setPassword(e.target.value);
    }
    const handleConfirmChange = (e) => {
        setConfirm(e.target.value);
    }
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(password.length < 8 || confirm.length < 8){
            toast.error("Password can not less than 8 characters");
            return;
        }
        if(password !== confirm){
            toast.error("Passwords don't match each other");
            return;
        }

        setSubmiting(true);
        requestNetwork('platform/passport/user_registration', {
            'email': email,
            'passwd': password,
            'confirm': confirm,
            'firstname': firstName,
            'lastname': lastName,
        }, true).then((res) => {
            if(res.error === 0 && res.data !== undefined) {
                setSubmiting(false);
                toast.success(res.message);
                handleverify(res.data);
            }else{
                toast.error(res.message);
                setSubmiting(false);
            }
        }).catch((err)=>{
            setSubmiting(false);
            toast.warn(err);
        });
    }

    const handleback = () => {
        navigate(`/login`);
    };

    const handleverify = (v) => {
        navigate(`/verify`, { state: v});
    };

    return (
        <Box sx={{ minHeight: '100vh'}}>
            <CssBaseline />
            <AppBar position="absolute" color="default" elevation={0} sx={{ position: 'relative', borderBottom: (t) => `1px solid ${t.palette.divider}` }}>
                <Toolbar>
                    <Typography variant="h4" color="inherit" noWrap>
                        Register Form for new User
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction="column" >
                <Grid item xs={12} container justifyContent="center" alignItems="flext-start" sx={{ minHeight: { xs: 'calc(100vh - 210px)', sm: 'calc(100vh - 210px)', md: 'calc(100vh - 210px)' } }}>
                    <Grid item style={{ display: "flex", alignItems: "flex-start"}} p={5}>
                        <Paper elevation={10} style={{ padding: '20px', minWidth: "600px"}}>
                            <form onSubmit={handleSubmit}>
                                <Stack direction="column" justifyContent="center" spacing={2}>
                                    <Typography variant="h4" color="inherit" noWrap>
                                        Sign Up
                                    </Typography>
                                    <Stack direction="row" alignItems='center' spacing={1}>
                                        <div style={{width: '120px', minWidth: '120px'}}>
                                            <Typography component="h2" align="right" >
                                                <b>First Name:</b>
                                            </Typography>
                                        </div>
                                        <TextField id="fname" size='small' sx={{ height: '44px', '& .MuiInputBase-root': {height: '44px', backgroundColor: 'white'} }} hiddenLabel placeholder='First Name' fullWidth autoComplete="off" onChange={(e) => setFirstname(e.target.value)} value={firstName} required/>
                                    </Stack>
                                    <Stack direction="row" alignItems='center' spacing={1}>
                                        <div style={{width: '120px', minWidth: '120px'}}>
                                            <Typography component="h2" align="right" >
                                                <b>Last Name:</b>
                                            </Typography>
                                        </div>
                                        <TextField id="lname" size='small' sx={{ height: '44px', '& .MuiInputBase-root': {height: '44px', backgroundColor: 'white'} }} hiddenLabel placeholder='Last Name' fullWidth autoComplete="off" onChange={(e) => setLastname(e.target.value)} value={lastName} required/>
                                    </Stack>
                                    <Divider />
                                    <Stack direction="row" alignItems='center' spacing={1}>
                                        <div style={{width: '120px', minWidth: '120px'}}>
                                            <Typography component="h2" align="right" >
                                                <b>E-mail:</b>
                                            </Typography>
                                        </div>
                                        <TextField id="email" size="small" sx={{ height: '44px', '& .MuiInputBase-root': {height: '44px', backgroundColor: 'white'} }} hiddenLabel placeholder='E-Mail' fullWidth autoComplete="off" onChange={(e) => setEmail(e.target.value)} value={email} required/>
                                    </Stack>
                                    <Stack direction="row" alignItems='center' spacing={1}>
                                        <div style={{width: '120px', minWidth: '120px'}}>
                                            <Typography component="h2" align="right" >
                                                <b>Password:</b>
                                            </Typography>
                                        </div>
                                        <OutlinedInput placeholder="Enter New Password" id="password-password"
                                            type={showNewPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={handleChange}
                                            name="password"
                                            size='small'
                                            fullWidth
                                            sx={{ height: '44px', '& .MuiInputBase-root': {height: '44px', backgroundColor: 'white'} }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        size="small"
                                                        color="secondary" >
                                                        {showNewPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            inputProps={{}}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems='center' spacing={1}>
                                        <div style={{width: '120px', minWidth: '120px'}}>
                                            <Typography component="h2" align="right" >
                                                <b>Confirm:</b>
                                            </Typography>
                                        </div>
                                        <OutlinedInput 
                                            placeholder="Enter Confirm Password"
                                            id="password-confirm"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            value={confirm}
                                            name="confirm"
                                            fullWidth
                                            sx={{ height: '44px', '& .MuiInputBase-root': {height: '44px', backgroundColor: 'white'} }}
                                            onChange={handleConfirmChange}
                                            size='small'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        size="small"
                                                        color="secondary">
                                                        {showConfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            inputProps={{}}
                                        />
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <Button size="large" onClick={handleback} disableElevation color='secondary' variant="contained" fullWidth disabled={isSubmiting}>Back
                                        </Button>
                                        <Button type='submit' size="large" disableElevation color='success' variant="contained" fullWidth disabled={isSubmiting}>{isSubmiting ? <CircularProgress
                                            color="secondary" /> : 'Sign Up'}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RegisterScreen