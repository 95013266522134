import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Container, Paper, Stack, TextField,  Button, Typography, OutlinedInput, IconButton, InputAdornment } from '@mui/material';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { requestNetwork } from '../../services/http-service';
import useAuth from '../../hooks/useAuth';
import LoadingButton from '../../components/LoadingButton';

const LoginScreen = () => {
    const setAuth = useAuth();
    const navigate = useNavigate();
    const errRef = useRef();
    
    const [pwd, setPwd] = useState('');
    const [email, setEmail] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isSubmiting, setSubmiting] = useState(false);

    const handleShowPasswordChange = (e) => {
        setPwd(e.target.value);
    }
    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setErrMsg('');
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmiting(true);
        requestNetwork('platform/passport/user_login', {
            'email': email,
            'passwd': pwd
        }, true).then((res) => {
            if(res !== undefined && res.error === 0 && res.data) {
                setEmail('');
                setPwd('');

                setAuth.setUser(res.data.user);
                setAuth.setToken(res.data.token);
                navigate("/", {replace: true});
            }else{
                setErrMsg(res.message);
                setSubmiting(false);
            }
        }).catch((err)=>{
            setSubmiting(false);
            setErrMsg(err);
        });
    }

    const handleNewUser = () => {
        navigate(`/register`);
    };

    return (
        <Box sx={{ minHeight: '100vh', backgroundSize: '600px 600px', }}>
            <Typography variant="h1" sx={{ textDecoration: 'none', maxWidth: '500px', position: 'absolute', padding: '40px', color: '#000000', zIndex: -100 }} >
                Ready to take your business growth to the next level?
            </Typography>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12} container justifyContent="center" alignItems="center" sx={{ minHeight: { xs: 'calc(100vh - 210px)', sm: 'calc(100vh - 210px)', md: 'calc(100vh - 210px)' } }}>
                    <Container className="container" maxWidth="xs">
                        <Grid item style={{ display: "flex", alignItems: "center", height: '100vh'}}>
                            <Paper elevation={10} style={{padding: '20px', minWidth: '320px'}}>
                                <Stack justifyContent="center" alignItems="center"><p style={{fontSize: '24px'}} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p></Stack>
                                <form onSubmit={handleSubmit}>
                                    <Stack direction="column" justifyContent="center" spacing={2} >
                                        <Grid align='center'>
                                            {/* <img src={AppLogo} style={{height: 80, width: 220}} alt="Logo"/> */}
                                            <h2>Sign In</h2>
                                        </Grid>
                                        <TextField id="email" size="small" fullWidth sx={{ height: '44px', '& .MuiInputBase-root': {height: '44px', backgroundColor: 'white'} }} hiddenLabel placeholder='E-Mail' autoComplete="off" onChange={(e) => setEmail(e.target.value)} value={email} required/>
                                        <OutlinedInput placeholder="Enter Login Password" id="password-password"
                                            type={showNewPassword ? 'text' : 'password'}
                                            value={pwd}
                                            onChange={handleShowPasswordChange}
                                            name="password"
                                            fullWidth
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        size="small"
                                                        color="secondary" >
                                                        {showNewPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            inputProps={{}}
                                            sx={{ height: '44px', '& .MuiInputBase-root': {padding: "5px", height: '44px', backgroundColor: 'white'} }}
                                        />
                                        <Typography variant="h5" align='right'>
                                            Forgot Password?
                                        </Typography>
                                        <Stack direction="row" spacing={2}>
                                            <Button size="large" onClick={handleNewUser} disableElevation color='warning' variant="contained" fullWidth disabled={isSubmiting}> Register
                                            </Button>
                                            <LoadingButton type='submit' size="large" disableElevation color='primary' variant="contained" fullWidth loading={isSubmiting} disabled={isSubmiting}>Sign in
                                            </LoadingButton>
                                        </Stack>
                                    </Stack>
                                </form>
                            </Paper>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
};

export default LoginScreen