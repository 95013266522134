import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Container, Paper, Stack, TextField, Select, MenuItem, Button, CircularProgress, Typography, OutlinedInput, IconButton, InputAdornment, AppBar, Toolbar, CssBaseline, Divider } from '@mui/material';
import { EyeOutlined, EyeInvisibleOutlined, LineOutlined } from '@ant-design/icons';
import { requestNetwork } from '../../services/http-service';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';

const VerifyOptScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    const [isSubmiting, setSubmiting] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [opt, setOpt] = useState('');

    useEffect(() => {
        console.log(state);
        if(state === undefined || state === ""){
            handleback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(opt.length < 5){
            toast.error("OPT code is not write");
            return;
        }

        setSubmiting(true);
        requestNetwork('platform/passport/user_opt_verification', {
            'email': state,
            'opt_code': opt,
        }, true).then((res) => {
            if(res.error === 0 && res.data !== undefined) {
                setSubmiting(false);
                toast.success(res.message);
                navigate(`/login`);
            }else{
                toast.error(res.message);
                setSubmiting(false);
            }
        }).catch((err)=>{
            setSubmiting(false);
            toast.warn(err);
        });
    }

    const handleback = () => {
        navigate(`/register`);
    };

    return (
        <Box sx={{ minHeight: '100vh'}}>
            <CssBaseline />
            <AppBar position="absolute" color="default" elevation={0} sx={{ position: 'relative', borderBottom: (t) => `1px solid ${t.palette.divider}` }}>
                <Toolbar>
                    <Typography variant="h4" color="inherit" noWrap>
                        Verify Email Address
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction="column" >
                <Grid item xs={12} container justifyContent="center" alignItems="flext-start" sx={{ minHeight: { xs: 'calc(100vh - 210px)', sm: 'calc(100vh - 210px)', md: 'calc(100vh - 210px)' } }}>
                    <Grid item style={{ display: "flex", alignItems: "flex-start"}} p={5}>
                        <Paper elevation={10} style={{ padding: '20px', minWidth: "400px"}}>
                            <form onSubmit={handleSubmit}>
                                <Stack direction="column" justifyContent="center" spacing={2}>
                                    <Typography variant="h4" color="inherit" noWrap>
                                        Verification Code
                                    </Typography>
                                    <Stack direction="row" alignItems='center' spacing={1}>
                                        <TextField id="vemail" placeholder='Enter Code' size='small' sx={{ height: '44px', '& .MuiInputBase-root': {height: '44px', backgroundColor: 'white'} }} hiddenLabel  fullWidth autoComplete="off" onChange={(e) => setOpt(e.target.value)} value={opt} required/>
                                    </Stack>
                                    <Button size="large" type='submit' disableElevation color='primary' variant="contained" fullWidth disabled={isLoading}>{isLoading ? <CircularProgress
                                        color="secondary" /> : 'Verify Now'}
                                    </Button>
                                </Stack>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default VerifyOptScreen