import React, { lazy, Suspense } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import ThemeCustomization from './themes'
import Loadable from './components/Loadable';

import LoginScreen from './pages/authen/LoginScreen';
import RegisterScreen from './pages/authen/RegisterScreen';
import VerifyOptScreen from './pages/authen/VerifyOptScreen';
import MissingScreen from './components/Missing';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainLayout = Loadable(lazy(() => import('./layouts/MainLayout')));

//home screen
const HomeDashboardScreen = Loadable(lazy(() => import('./pages/home/HomeDashboardScreen')));
//customer screen
const CustomerScreen = Loadable(lazy(() => import('./pages/customer/CustomerScreen')));
//stores screen
const ShopScreen = Loadable(lazy(() => import('./pages/stores/ShopScreen')));
const NewShopScreen = Loadable(lazy(() => import('./pages/stores/NewShopScreen')));
const EditShopScreen = Loadable(lazy(() => import('./pages/stores/EditShopScreen')));
const ProductScreen = Loadable(lazy(() => import('./pages/stores/products/ProductScreen')));
const ReportShopScreen = Loadable(lazy(() => import('./pages/stores/ReportShopScreen')));
const StaffScreen = Loadable(lazy(() => import('./pages/staffs/StaffScreen')));
const NewStaffScreen = Loadable(lazy(() => import('./pages/staffs/NewStaffScreen')));
//seeting screen
const SettingScreen = Loadable(lazy(() => import('./pages/setting/SettingScreen')));

function App() {
    return (
        <ThemeCustomization>
            <Suspense fallback={<div className="container">Loading...</div>}>
                <Routes>
                    <Route path="login" element={<LoginScreen />} />
                    <Route path="register" element={<RegisterScreen />} />
                    <Route path="verify" element={<VerifyOptScreen />} />
                    <Route path="/" element={<MainLayout />}>
                        <Route index element={<HomeDashboardScreen />}/>
                        <Route path="home" >
                            <Route index element={<HomeDashboardScreen />}/>
                        </Route>
                        <Route path="customer" >
                            <Route index element={<CustomerScreen />}/>
                        </Route>
                        <Route path="shop" >
                            <Route index element={<ShopScreen />}/>
                            <Route path="new" element={<NewShopScreen />}/>
                            <Route path="edit" element={<EditShopScreen />}/>
                            <Route path="product" element={<ProductScreen />}/>
                            <Route path="report" element={<ReportShopScreen />}/>
                        </Route>
                        <Route path="staff" >
                            <Route index element={<StaffScreen />}/>
                            <Route path="new" element={<NewStaffScreen />}/>
                        </Route>
                        <Route path="setting" >
                            <Route index element={<SettingScreen />}/>
                        </Route>
                    </Route>
                    <Route path="*" element={<MissingScreen />} />
                </Routes>
            </Suspense>
            <ToastContainer position="top-center" autoClose={1000}/>
        </ThemeCustomization>
    );
}

export default App;