import React from 'react';
import { useNavigate } from "react-router-dom"
// project import
import useAuth from '../hooks/useAuth';
// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Button, Grid, Stack, Typography } from '@mui/material';
// assets
import error500 from '../assets/images/Error500.png';

const Missing = () => {
    const theme = useTheme();
    const auth = useAuth();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const tohome = () => {
        auth.setUrl("");
        navigate("/", {replace: false});
    };

    return (
        <>
            <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Box sx={{ width: { xs: 350, sm: 396 } }}>
                        <img src={error500} alt="mantis" style={{ height: '100%', width: '100%' }} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Stack justifyContent="center" alignItems="center">
                        <Typography align="center" variant={matchDownSM ? 'h2' : 'h1'}>
                            Internal Server Error
                        </Typography>
                        <Typography color="textSecondary" variant="body2" align="center" sx={{ width: { xs: '73%', sm: '70%' }, mt: 1 }}>
                            Server error 500. we fixing the problem. please try again at a later stage.
                        </Typography>
                        <Button onClick={tohome}  variant="contained" sx={{ textTransform: 'none', mt: 4 }}>
                            Retry
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default Missing